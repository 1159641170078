<template>
    <div class="pageContol listWrap templateList">
        <div class="framePage">
            <div class="framePage-title">
                <span>
                    <em>当前位置：</em>
                    <a href="javascript:;">分销管理</a>
                    <i>></i>
                    <a
                        href="javascript:;"
                        class="cur-a"
                    >分销商列表</a>
                </span>
            </div>
            <div class="framePage-body">
                <div class="operationControl">
                    <div class="searchbox">
                        <div
                            title="分销商"
                            class="searchboxItem"
                        >
                            <span class="itemLabel">分销商:</span>
                            <el-input
                                v-model="distributeMobile"
                                type="text"
                                size="small"
                                style="width:auto"
                                placeholder="请输入分销商"
                                clearable
                            />
                        </div>
                        <div
                            title="审核状态"
                            class="searchboxItem"
                        >
                            <span class="itemLabel">审核状态:</span>
                            <el-select
                                v-model="auditState"
                                placeholder="请选择"
                                size="small"
                                clearable
                            >
                                <el-option
                                    label="待审核"
                                    value="10"
                                ></el-option>
                                <el-option
                                    label="已通过"
                                    value="20"
                                ></el-option>
                                <el-option
                                    label="已驳回"
                                    value="30"
                                ></el-option>
                            </el-select>
                        </div>
                        <div>
                            <el-button
                                class="bgc-bv"
                                @click="getData()"
                            >查询</el-button>
                        </div>
                    </div>
                </div>
                <div class="framePage-scroll">
                    <div class="ovy-a">
                        <el-table
                            ref="multipleTable"
                            :data="tableData"
                            :height="tableHeight"
                            size="small"
                            tooltip-effect="dark"
                            style="width: 100%"
                            :header-cell-style="tableHeader"
                        >
                            <el-table-column
                                label="序号"
                                align="center"
                                type="index"
                            />
                            <el-table-column
                                label="分销商"
                                align="center"
                                prop="distributeMobile"
                                show-overflow-tooltip
                            />
                            <el-table-column
                                label="上级推荐会员"
                                align="center"
                                prop="upperMobile"
                                show-overflow-tooltip
                            >
                                <template slot-scope="scope">{{scope.row.upperMobile?scope.row.upperMobile:'无'}}</template>
                            </el-table-column>
                            <el-table-column
                                label="下属会员数量"
                                align="center"
                                prop="lowerNum"
                            />
                            <el-table-column
                                label="已得佣金（元）"
                                align="center"
                                prop="brokerage"
                            />
                            <el-table-column
                                label="已提现金额（元）"
                                align="center"
                                prop="withdrawAmount"
                            />
                            <el-table-column
                                label="佣金比例"
                                align="center"
                                prop="percentage"
                            >
                                <template slot-scope="scope">
                                    <span @click="handlePass(scope.row,2)">{{scope.row.percentage}} %<i class="el-icon-edit"></i></span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                label="审核状态"
                                align="center"
                                prop="auditState"
                            >
                                <template slot-scope="scope">
                                    {{scope.row.auditState | getState}}
                                </template>
                            </el-table-column>

                            <el-table-column
                                label="操作"
                                align="center"
                                width="240px"
                            >
                                <div
                                    slot-scope="scope"
                                    class="flexcc"
                                >
                                    <el-button
                                        size="mini"
                                        type="text"
                                        v-if="scope.row.auditState !='20'"
                                        @click="handlePass(scope.row,1)"
                                    >通过</el-button>
                                    <el-button
                                        size="mini"
                                        type="text"
                                        v-if="scope.row.auditState !='30'"
                                        @click="handleReject(scope.row.distributeId)"
                                    >驳回</el-button>
                                    <el-button
                                        size="mini"
                                        type="text"
										:disabled="scope.row.auditState != '20'"
                                        @click="handleOrder(scope.row)"
                                    >分销订单</el-button>
                                    <el-button
                                        size="mini"
                                        type="text"
										:disabled="scope.row.auditState != '20'"
                                        @click="handlewithdrawal(scope.row)"
                                    >提现记录</el-button>
                                </div>
                            </el-table-column>
                            <Empty slot="empty" />
                        </el-table>
                    </div>
                </div>
                <PageNum
                    :apiData="apiData"
                    @sizeChange="sizeChange"
                    @getData="getData"
                />
            </div>
        </div>
        <el-dialog
            title="佣金比例"
            :visible.sync="dialogFormVisible"
            :before-close="close"
            center
            width="30%"
        >
            <el-form
                ref="form"
                :model="form"
                :rules="rule"
                label-width="100px"
            >
                <el-form-item
                    label="佣金比例"
                    prop="percentage"
                >
                    <el-input
                        v-model="form.percentage"
                        size="small"
                        oninput="value=value.replace(/[^\d+(,\d\d\d)*.\d+$]/g,'')"
                    >
                        <template slot="append">%</template>
                    </el-input>
                </el-form-item>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button @click="close">取 消</el-button>
                <el-button
                    class="bgc-bv"
                    @click="handlePassApi"
                >确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
    name: "distributorList",
    components: {
        Empty,
        PageNum,
    },
    mixins: [List],
    data() {
        return {
            distributeMobile: "",
            auditState: "",
            dialogFormVisible: false,
            form: {
                percentage: "",
            },
            rule: {
                percentage: [
                    {
                        required: true,
                        message: "请输入佣金比例",
                        trigger: "bulr",
                    },
                ],
            },
            itemId: "",
            openType: 1,
        };
    },
    computed: {},
    filters: {
        getState(val) {
            let dic = {
                10: "待审核",
                20: "已通过",
                30: "已驳回",
            };
            return dic[val];
        },
    },
    methods: {
        getData(pageNum = 1) {
            const params = {
                pageNum: pageNum,
                pageSize: this.pageSize,
                auditState: this.auditState,
                distributeMobile: this.distributeMobile,
            };
            this.doFetch(
                {
                    url: "/biz/distribute/distributor/pageList",
                    params,
                    pageNum,
                },
                true,
                2
            );
        },
        // 驳回
        handleReject(id) {
            this.$post(
                "/biz/distribute/distributor/refuse",
                { distributeId: id },
                3000,
                true,
                2
            ).then((res) => {
                this.$message({
                    type: "success",
                    message: res.message,
                });
                this.getData(-1);
            });
        },
        //
        handleOrder(row) {
            this.$router.push({
                path: "/web/miniApp/retailOrderList",
                query: {
                    distributeMobile: row.distributeMobile,
                    stu: "1",
                },
            });
        },
        //
        handlewithdrawal(row) {
			;
            this.$router.push({
                path: "/web/miniApp/WithdrawalList",
                query: {
                    mobile: row.distributeMobile,
                    stu: "1",
                },
            });
        },
        // 审核通过 + 修改佣金比例
        handlePass(row, stu) {
            this.itemId = row.distributeId;
            this.dialogFormVisible = true;
            this.openType = stu;
            this.form.percentage = row.percentage;
        },
        handlePassApi() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.$post(
                        this.openType == 1
                            ? "/biz/distribute/distributor/pass"
                            : "/biz/distribute/distributor/modifyPercentage",
                        {
                            distributeId: this.itemId,
                            percentage: this.form.percentage,
                        },
                        3000,
                        true,
                        2
                    ).then((res) => {
                        ;
                        this.getData(-1);
                        this.close();
                    });
                }
            });
        },
        close() {
            this.dialogFormVisible = false;
            this.form = {
                percentage: "",
            };
            this.openType = 1;
        },
    },
};
</script>
<style lang="less"></style>
